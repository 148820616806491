<template>
  <el-dialog
    width="800px"
    :visible.sync="parentData.show"
    :title="parentData.title"
    :before-close="handleClickClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    v-loading="loading"
  >
    <el-row :gutter="20">
      <el-col :span="8">
        <el-upload
          action="upload"
          :show-file-list="false"
          :on-change="handleFileChange"
          :file-list="fileList"
          v-loading="coverLoading"
          class="cover"
          :limit="1"
          :auto-upload="false"
        >
          <el-image :src="form.avatar">
            <template #error>
              <div class="image-slot">
                <i style="font-size: 2rem" class="el-icon-picture-outline"></i>
              </div>
            </template>
            <template #placeholder>
              <div class="image-slot">加载中<span class="dot">...</span></div>
            </template>
          </el-image>
          <template #tip>
            <p>请上传尺寸大于 300 * 200 的图片</p>
          </template>
        </el-upload>
      </el-col>
      <el-col :span="16">
        <el-form ref="form" :model="form" :rules="rules" label-width="90px">
          <el-form-item label="角色" prop="roleId">
            <el-select
              size="small"
              v-model="form.roleId"
              placeholder="请选择"
              style="width: 100%"
              @change="handleChangeRole"
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="所属渠道商"
            prop="partnerId"
            v-show="showDistributor"
          >
            <el-select
              size="small"
              v-model="form.partnerId"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in distributors"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名" prop="username">
            <el-input
              size="small"
              v-model="form.username"
              :disabled="Boolean(parentData.data.id)"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="realName">
            <el-input size="small" v-model="form.realName"></el-input>
          </el-form-item>

          <el-form-item label="性别" prop="sex">
            <el-radio v-model="form.sex" :label="1">男</el-radio>
            <el-radio v-model="form.sex" :label="2">女</el-radio>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input
              size="small"
              type="number"
              v-model="form.phone"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="密码"
            prop="password"
            v-if="!Boolean(parentData.data.id)"
          >
            <el-input
              show-password
              size="small"
              v-model="form.password"
              placeholder="密码必须由8-16位字母、数字及下划线组成"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer text-center">
      <el-button type="primary" @click="onSubmit('form')">保存</el-button>
      <el-button @click="handleClickClose">关闭</el-button>
    </span>
    <el-dialog
      title="裁剪图片"
      :visible.sync="cropper.show"
      width="950px"
      :before-close="handleCloseCropper"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="crop-img">
        <img
          ref="cropper"
          :src="cropper.img"
          style="display: block; max-width: 100%"
          alt=""
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            :loading="cropper.loading"
            :disabled="cropper.loading"
            @click="handleCropperImg"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { addAccounts, modifyAccount } from "@/api/service";
import { uploadConfig, upload } from "@/api/upload";
import Cropper from "cropperjs";
import { fetchDistributors } from "@/api/distributor";
export default {
  emits: ["offIt"],
  props: ["parentData", "roleList"],
  data() {
    return {
      uploadConfig,
      loading: false,
      tree: [],
      targetRules: [],
      list: {},
      userData: {},
      activeName: 0,
      user: JSON.parse(localStorage.RLTuser),
      form: {
        username: "",
        password: "",
        realName: "",
        roleId: "",
        avatar: "",
        phone: "",
        sex: 1,
        type: 0
      },
      avatar:
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201710%2F24%2F20171024071632_hzJ8n.jpeg&refer=http%3A%2F%2Fb-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1621405878&t=5a8bbc125bfc0c85f73ca9e479bc9d04",
      comList: [],
      deptList: [],
      passShow: false,
      userId: "",
      rules: {
        username: [
          { required: true, message: "请输入用户名称", trigger: "blur" }
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "change"
          }
        ],
        phone: [
          {
            required: true,
            message: "请输入电话",
            trigger: "blur"
          }
        ],
        roleId: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change"
          }
        ],
        realName: [
          { required: true, message: "请填写用户姓名", trigger: "blur" }
        ]
      },
      cropper: {
        img: "",
        el: null,
        loading: false,
        show: false
      },
      fileList: [],
      coverLoading: false,
      // 渠道列表
      showDistributor: false,
      distributors: []
    };
  },
  mounted() {
    this.getDistributors();
    this.form = {
      username: "",
      password: "",
      realName: "",
      roleId: "",
      avatar: "",
      phone: "",
      sex: 1,
      type: 0
    };
    if (this.parentData.data.id) {
      this.form.id = this.parentData.data.id;
      this.form.username = this.parentData.data.username;
      this.form.avatar = this.parentData.data.profile.avatar;
      this.form.realName = this.parentData.data.profile.realName;
      this.form.roleId = this.parentData.data.role.id;
      this.form.phone = this.parentData.data.profile.phone;
      this.form.type = this.parentData.data.type - 1;
    }
  },
  methods: {
    //关闭窗口
    handleClickClose() {
      this.$emit("offIt");
    },
    async getDistributors() {
      let res = {};
      res = await fetchDistributors({ page: 1, size: 40 });
      if (res.code === 0) this.distributors = res.data.records;
    },
    handleChangeRole(role) {
      console.log(role);
      if (role === 8) {
        this.form.partnerId;
      } else {
        this.form.partnerId = "";
      }
      this.showDistributor = role === 8;
    },

    //确认用户信息
    async onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.form.id) {
            let params = {
              id: this.form.id,
              username: this.form.username,
              avatar: this.form.avatar,
              realName: this.form.realName,
              roleId: this.form.roleId,
              phone: this.form.phone,
              type: this.form.type
            };
            this.updatauc(params);
          } else {
            this.adduc(this.form);
          }
        } else {
          return false;
        }
      });
    },
    //添加
    async adduc(data) {
      try {
        let res = await addAccounts(data);
        if (res.code === 0) {
          this.$message.success("新增成功！");
          this.$emit("offIt", true);
        } else {
          this.$message.error(res.message);
        }
      } catch (err) {
        this.$message.error("操作失败！");
      }
      this.loading = false;
    },
    //修改
    async updatauc(data) {
      let res = await modifyAccount(data);
      if (res.code === 0) {
        this.$message.success("修改成功！");
        this.$emit("offIt", true);
      } else {
        this.$message.error(res.message);
      }
      this.loading = false;
    },

    handleUploadImg(file) {
      console.log(file);
      if (file && file.response && file.response.code === 0) {
        this.form.avatar = file.response.data.visitUri;
      }

      console.log(this.form.avatar);
    },
    handleRemoveCover() {
      this.form.avatar = "";
    },
    handleFileChange(file) {
      this.cropper.show = true;
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        this.cropper.img = reader.result;
        this.fileList = [];

        if (this.cropper.el) {
          this.cropper.el.replace(this.cropper.img);
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              this.cropper.el = new Cropper(this.$refs.cropper, {
                aspectRatio: 2 / 3,
                viewMode: 1
                // crop(event) {
                //   console.log(event);
                // },
              });
            });
          }, 100);
        }
      });
      if (file) {
        reader.readAsDataURL(file.raw);
      }
    },
    async handleCropperImg() {
      this.cropper.loading = true;
      let cas = this.cropper.el.getCroppedCanvas();
      cas.toBlob(async data => {
        const file = new File(
          [data],
          `${this.form.realName || "touxiang"}.png`
        );
        try {
          let res = await upload(file);
          if (res.code === 0) {
            this.form.avatar = res.data.visitUri;
            this.cropper.show = false;
            console.log(this.cropper.show);
          }
        } catch (error) {
          this.$message.error("上传失败");
          throw error;
        }
        this.cropper.loading = false;
      });
    },
    handleCloseCropper() {
      this.cropper.show = false;
      this.cropper.img = "";
    }
  }
};
</script>
<style lang="scss" scoped>
.el-form .el-form-item {
  margin-bottom: 15px !important;
}
.cover {
  ::v-deep {
    .el-upload {
      width: 100%;
      text-align: left;
    }
    .el-image {
      width: 200px;
      height: 300px;
    }
    .image-slot {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #efefef;
      color: #666;
    }
  }
}
.bottom-bar {
  position: fixed;
  z-index: 1;
  bottom: 0px;
  margin: 0 -20px;
  width: calc(100% - 200px);
}
.page-toolbar {
  position: fixed;
  background: #fff;
  z-index: 1;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-top: 1px solid #ccc;
  width: 770px;
  text-align: right;
  // bottom: 0px;
  // padding: 10px;
  // border-top: 1px solid #ccc;
  // width: 700px;
  // text-align: right;
  // background: #fff;
  // -webkit-box-shadow: 0 1px 3px rgba(18, 18, 18, 0.1);
  // box-shadow: 0 -1px 3px rgba(18, 18, 18, 0.1);
}
.cropper {
  width: 400px;
  height: 600px;
}
</style>
<style>
.cropper-view-box,
.cropper-face {
  /* border-radius: 50%; */
}

.cropper-container {
  font-size: 0;
  line-height: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
}

.cropper-container img {
  /* Avoid margin top issue (Occur only when margin-top <= -height) */
  display: block;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  width: 100%;
  height: 100%;
  image-orientation: 0deg;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cropper-wrap-box {
  overflow: hidden;
}

.cropper-drag-box {
  opacity: 0;
  background-color: #fff;
}

.cropper-modal {
  opacity: 0.5;
  background-color: #000;
}

.cropper-view-box {
  display: block;
  overflow: hidden;

  width: 100%;
  height: 100%;

  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
}

.cropper-dashed {
  position: absolute;

  display: block;

  opacity: 0.5;
  border: 0 dashed #eee;
}

.cropper-dashed.dashed-h {
  top: 33.33333%;
  left: 0;
  width: 100%;
  height: 33.33333%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.cropper-dashed.dashed-v {
  top: 0;
  left: 33.33333%;
  width: 33.33333%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}

.cropper-center {
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;

  width: 0;
  height: 0;

  opacity: 0.75;
}

.cropper-center:before,
.cropper-center:after {
  position: absolute;
  display: block;
  content: " ";
  background-color: #eee;
}

.cropper-center:before {
  top: 0;
  left: -3px;
  width: 7px;
  height: 1px;
}

.cropper-center:after {
  top: -3px;
  left: 0;
  width: 1px;
  height: 7px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.cropper-face {
  top: 0;
  left: 0;
  background-color: #fff;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}

.cropper-line.line-n {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}

.cropper-line.line-w {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}

.cropper-line.line-s {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}

.cropper-point {
  width: 5px;
  height: 5px;

  opacity: 0.75;
  background-color: #39f;
}

.cropper-point.point-e {
  top: 50%;
  right: -3px;
  margin-top: -3px;
  cursor: e-resize;
}

.cropper-point.point-n {
  top: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}

.cropper-point.point-w {
  top: 50%;
  left: -3px;
  margin-top: -3px;
  cursor: w-resize;
}

.cropper-point.point-s {
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}

.cropper-point.point-ne {
  top: -3px;
  right: -3px;
  cursor: ne-resize;
}

.cropper-point.point-nw {
  top: -3px;
  left: -3px;
  cursor: nw-resize;
}

.cropper-point.point-sw {
  bottom: -3px;
  left: -3px;
  cursor: sw-resize;
}

.cropper-point.point-se {
  right: -3px;
  bottom: -3px;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  opacity: 1;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    width: 5px;
    height: 5px;
    opacity: 0.75;
  }
}

.cropper-point.point-se:before {
  position: absolute;
  right: -50%;
  bottom: -50%;
  display: block;
  width: 200%;
  height: 200%;
  content: " ";
  opacity: 0;
  background-color: #39f;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url("data:image/png");
}

.cropper-hide {
  position: absolute;

  display: block;

  width: 0;
  height: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
</style>
